import React from "react"
import { useLocation } from 'react-router-dom';
import fetchData from './../services/fetchData';
import config from "./../config";

import CalificarConsulta from "./../components/CalificarConsulta"
import HeaderSalaEspera from "./../components/HeaderSalaEspera"

import logo from "./../assets/img/logoHospitelec.png"

const URLAPI = config.urlApi;

export default function Gracias(){

    const location = useLocation();
    const idCita = location.pathname.split('/')[2];

    React.useEffect(() => {
        getDatosDeCita()
    } , [])

    const getDatosDeCita = () => {
        fetchData('GET', `${URLAPI}cita/${idCita}/opinion`)
            .then(data => verificarCalificacion(data))
            .catch(err => {
                console.log("ha pteao", err);
            })
    }

    const verificarCalificacion = (data) => {
        if(data.length > 0){
            window.location.href = `/`;
        }
    }

    return(
        <section>
            <HeaderSalaEspera imagen={logo}></HeaderSalaEspera>
            <CalificarConsulta />
        </section>
    )
}