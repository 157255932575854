import React from 'react';
import fetchData from './../../services/fetchData';
import config from "./../../config";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import "./NuestrosDoctores.css"
const URLAPI = config.urlApi;

const state= {
    responsive:{
        0: {
            items: 1,
        },
        576: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 3,
        },
        1200: {
            items: 4,
        },
    },
}

function NuestrosDoctores({imagenCardDoc}){

    const [doctores, setDoctores] = React.useState([]);

    React.useEffect(()=>{
        getDoctores()
    },[])

    const getDoctores = () => {
        fetchData('GET', `${URLAPI}doctor`)
            .then(data => setDoctores(data))
            .catch(err => {
                console.log("ha pteao", err);
            })
    }

    return(

        <div className="container-fluid nuestrosDoctores" id="misDoctores">

            <div className="container noPadding-sm noPadding-xs nuestrosDoctores__container">

                <h1 className="nuestrosDoctores__titulo">NUESTROS PROFESIONALES DE LA SALUD</h1>

                {
                    doctores.length >= 1 &&
                    (
                        <OwlCarousel className='owl-theme'
                            margin={10}
                            autoplay={false}
                            loop={true}
                            dots={true}
                            touchDrag={true}
                            animateIn={true}
                            responsive={state.responsive}
                            center={false}
                            stagePadding={30}
                        >   
                            {
                                doctores.map((doctor)=>{
                                    return <div key={doctor.id_doctor} className="card__nuestrosDoctores">

                                        <div className="header__nuestrosDoctores">

                                            <img src={doctor.url_foto_perfil} alt="" className="nuestrosDoctores__img" />

                                            {/* <i className="fa-solid fa-circle-check nuestrosDoctores__icon"></i> */}

                                        </div>

                                        <div className="body__nuestrosDoctores">

                                            <span className="nuestrosDoctores__nombreDoctor">{`${doctor.nombre} ${doctor.apellido_materno} ${doctor.apellido_paterno}`}</span> <br/>

                                            <span className="nuestrosDoctores__nombreEspecialidad">{doctor.especialidad}</span> <br/>

                                            {/* <span className="nuestrosDoctores__añosExperiencia">10 años de experiencia</span> <br/> */}

                                            {/* <a href="#comenzarTeleorientacion" className="nuestrosDoctores__button">

                                                <button className="button__tele">

                                                    Hacer teleorientacion

                                                </button>

                                            </a> */}

                                        </div>

                                    </div>
                                })
                            }
                        </OwlCarousel>

                    )
                }


                {/* <a href="#" className="nuestrosDoctores__doctores">

                    <span className="doctores__text">VER TODOS LOS DOCTORES</span>

                </a> */}

            </div>

        </div>
    )
}

export default NuestrosDoctores;