import "./NewTiempoEspera.css"

function NewTiempoEspera ({imagenSalaEspera}){
    return(

        <div className="container-fluid tiempoEspera">

            <div className="container tiempoEspera__container noPadding-md noPadding-sm noPadding-xs">

                <div className="tiempoEspera__infoSala">

                    <div className="infoSala__inicio">

                        <h1 className="infoSala__titulo">SALA DE <p className="infoSala__titulo1">ESPERA</p></h1>

                        <div className="text-center infoSala__corazon">

                            <div className="lds-heart"><div></div></div>

                        </div>

                    </div>
                    
                    <img src={imagenSalaEspera} alt="" className="infoSala__img" />

                    <div className="infoSala__textTp">

                        <span className="textTp__1">TU TIEMPO DE<p>ESPERA ES DE:</p></span>

                        <span className="textTp__2">15 MIN.</span>

                        <span className="textTp__3 col-sm-0 col-xs-0">TU TELEORIENTACIÓN COMENZARÁ PRONTO</span>

                    </div>

                    <div className="col-xl-0 col-lg-0 col-md-0 infoSala__textTp2">

                        <span className="textTp__3">TU TELEORIENTACIÓN COMENZARÁ PRONTO</span>

                    </div>


                </div>

            </div>

        </div>
    )
    
}

export default NewTiempoEspera;