import React from 'react';
import { useLocation } from 'react-router-dom';
import fetchData from './../services/fetchData';
import config from "./../config";
import DailyIframe from '@daily-co/daily-js';

// IMAGENES
import imgDocSala from "./../assets/img/gatoXd.jepg"
import imgVistaPrevia from "./../assets/img/dosificacion_alginato.jpg"
import imgLogoPrivado from "./../assets/img/hospital_privado_logo.png"
import logo from "./../assets/img/logoHospitelec.png"

import HeaderSalaEspera from "./../components/HeaderSalaEspera"
import InfoDoc from "./../components/InfoDoc"
import CardPresentacion from "./../components/CardPresentacion"
import FooterSalaEspera from "./../components/FooterSalaEspera"

import imgSalaEspera from "./../assets/img/sala_espera.gif"
import imgLogoHP from "./../assets/img/hospital_privado_logo.png"
import imgCheckJunior from "./../assets/img/checkUp_junior.png"
import imgKidHospitelec from "./../assets/img/kid_hospitelec.png"
import imgUrgenciaPediatrica from "./../assets/img/urgencia_pediatrica.png"

import NewTiempoEspera from "./../components/NewTiempoEspera";
import Slider from "./../components/Slider";
import NewFooterSalaEspera from "./../components/NewFooterSalaEspera";

const URLAPI = config.urlApi;

export default function SalaEspera({ children }){
    
    const location = useLocation();
    const idCita = location.pathname.split('/')[2];

    const [numeroPacientes, setNumeroPacientes] = React.useState(0);
    const [urlRoomCita, setUrlRoomCita] = React.useState('');

    function getNumeroPacientesSalaEspera(){
        fetchData('GET', `${URLAPI}cita/beforeAttending`)
            .then(data => setNumeroPacientes(data.length))
            .catch(err => {
                console.log("ha pteao", err);
            })
    }

    function getDatosDeCita(refresh){
        fetchData('GET', `${URLAPI}cita/${idCita}`)
            .then(data => verificarCita(data, refresh))
            .catch(err => {
                console.log("ha pteao", err);
            })
    }

    async function verificarCita(data, refresh){
        const urlCita = data[0].url_cita_room;
        console.log("data", data);
        if(urlCita !== null && urlCita !== undefined && urlRoomCita === ""){
            clearInterval(refresh);
            // MOSTRAR UN BOTON AL USUARIO PARA QUE VAYA A LA TELEORIENTACIÓN
            window.location.href = `/cita/${idCita}`;
        }

    }

    React.useEffect(() => {
        getNumeroPacientesSalaEspera()
        var refresh = setInterval(function() {
            if(urlRoomCita === ""){
                getNumeroPacientesSalaEspera()
                getDatosDeCita(refresh)
            }
          }, 10000);
    } , []);

    return(
        <section> {/*
            <HeaderSalaEspera imagen={logo}/>

            <InfoDoc 
                imagenDocSala={imgDocSala} 
                imagenVistaPrevia={imgVistaPrevia} 
                numeroPacientesEspera={numeroPacientes}
            />

            <CardPresentacion imagenLogoPrivado={imgLogoPrivado}/>
            <FooterSalaEspera />
            
            */}

            <HeaderSalaEspera imagen={logo}></HeaderSalaEspera>
            <NewTiempoEspera imagenSalaEspera={imgSalaEspera} />
            <Slider>
                    <div className="carousel-item active">
                      
                      <div className="row row__slider">

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">

                          <div className="sliderHospitelec">

                            <img src={imgLogoHP} alt="" className="sliderHospitelec__logoHP" />

                            <div className="sliderHospitelec__infoEspecialidad">

                              <img src={imgCheckJunior} alt="" className="infoEspecialidad__junior" />

                              <img src={imgKidHospitelec} alt="" className="infoEspecialidad__kid" />

                            </div>

                          </div>

                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">

                          <div className="sliderHospitelec">

                            <span className="sliderHospitelec__precio">$700 MXN</span>

                            <div className="sliderHospitelec__card">

                              <div className="headerCard">

                                <span className="headerCard__text">Incluye:</span>

                              </div>

                              <div className="bodyCard">

                                <ul className="bodyCard__lista">

                                  <li>

                                    <i className="fa-solid fa-dash lista__iconoJunior"></i>
                                    <span className="lista__servicioJunior">Consulta pediátrica</span>

                                  </li>

                                  <li>

                                    <i className="fa-solid fa-dash lista__iconoJunior"></i>
                                    <span className="lista__servicioJunior">Consulta con ortopedista</span>

                                  </li>

                                  <li>

                                    <i className="fa-solid fa-dash lista__iconoJunior"></i>
                                    <span className="lista__servicioJunior">Prueba COVID-19</span>

                                  </li>

                                  <li>

                                    <i className="fa-solid fa-dash lista__iconoJunior"></i>
                                    <span className="lista__servicioJunior">Certificado Médico</span>

                                  </li>

                                </ul>

                              </div>

                            </div>

                          </div>

                        </div>

                      </div>

                    </div>
                    
            </Slider>

            <NewFooterSalaEspera imagenUrgenciaPediatrica={imgUrgenciaPediatrica}></NewFooterSalaEspera>
            
        </section>
    );
}