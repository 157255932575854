import "./SaludProfesional.css"

function SaludProfesional ({imagenSalud}){
    return(

        <div className="container-fluid saludProfesional">

            <div className="container saludProfesional__container noPadding-sm">

                <div className="row saludProfesional__row">

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">

                        <div className="saludProfesional__img">

                            <img src={imagenSalud} alt="" className="img__telemedicina" />

                        </div>

                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">

                        <div className="info__saludProfesional">

                            <h2 className="saludProfesional__titulo">Teleorientación Médica GRATUITA (TMG) impartida por profesionales de la salud para ti y toda tu familia.</h2>
                            {/* <h2 className="saludProfesional__titulo">Teleorientación Médica GRATUITA (TMG) impartida por profesionales de la salud para ti y toda tu familia.</h2> */}

                            <p className="saludProfesional__parrafo">Al alcance de un dispositivo, en donde estés, donde la necesites, todos los días de 9 a 7.</p>

                            <div className="saludProfesional__boton">


                                <a href="#comenzarTeleorientacion" className="paginaPrincipal__button">

                                    <button className="saludProfesional__button">

                                        Quiero una TMG

                                    </button>

                                </a>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    )
}

export default SaludProfesional;