import "./NewFooterSalaEspera.css"

function NewFooterSalaEspera ({imagenUrgenciaPediatrica}) {
    return(

        <div className="container-fluid footerSala">

            <div className="container footerSala__container noPadding-md noPadding-sm noPadding-xs">

                <div className="row footerSala__row">

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">

                        <div className="footerSala__imagen">

                            <img src={imagenUrgenciaPediatrica} alt="" className="imagen__footer" />

                        </div>

                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">

                        <div className="footerSala__horario">

                            <span className="horario__text">LAS 24 HORAS</span>

                        </div>

                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">

                        <div className="footerSala__contacto">

                            <div className="contacto">

                                <div className="contacto__iconoCirculo">

                                    <div className="contacto__circulo"></div>
                                    <i className="fa-solid fa-phone contacto__iconoTEL"></i>

                                </div>

                                
                                <span className="contacto__number">55 4424 3368</span>

                            </div>

                            <div className="contacto">

                                <i className="fa-brands fa-whatsapp contacto__iconoWA"></i>
                                <span className="contacto__number">55 6460 9745</span>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default NewFooterSalaEspera;