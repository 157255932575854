import "./Inicio.css"

function Inicio ({ imagen }){
    return(
        <div className="container-fluid pagPrinHospitelec">

            <div className="container noPadding-sm noPadding-xs pagPrinHospitelec__container">

                <div className="row">

                    <div className="col-xl-1 col-lg-0 col-md-0 col-sm-0 col-xs-0">

                    </div>

                    <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-xs-12">

                        <div className="introduccion__paginaPrincipal"> 

                            <h1 className="text__claro">
                                <span className="text__fuerte">Teleorientación Médica Gratuita</span>
                                {` (TMG)`} desde tu dispositivo, hasta donde la necesites, diario de 9 a 7.
                            </h1>

                            <p className="text__parrafo">¿Tienes dudas sobre tu salud, te preocupa un dolor o síntoma, necesitas una segunda opinión?</p>

                            <a href="#comenzarTeleorientacion" className="paginaPrincipal__button">

                                <button className="button__teleorientacion">

                                    Comienza AQUÍ tu TMG

                                </button>

                            </a>

                        </div>
                    
                    </div>

                    <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12 col-xs-12">

                        <div className="introduccion__img">

                            <img src={imagen} alt="" className="img__paginaPrincipal" />

                        </div>

                    </div>

                    <div className="col.xl-1 col-lg-0 col-mg-0 col-sm-0 col-xs-0">

                    </div>

                </div>

            </div>

        </div>
    )
}

export default Inicio;