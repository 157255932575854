import React from 'react'
import logoHPE from './../../assets/img/LogoHPE.svg'
import './Footer.css'

export default function Footer(){
    return(
        <footer className="container-fluid bg-azulHospitelec">
            <div className="container noPadding-xs noPadding-sm" id="footer">
                <p>
                    Este servicio de Teleorientación Médica Gratuita (TMG) cuenta con el respaldo del:
                </p>
                <img src={logoHPE} />
            </div>

            <hr></hr>

            <div className="container d-flex justify-content-center align-items-center" id="siguenosRedesSociales">
                <p className="text-white mr-5">
                    Síguenos en nuestras redes sociales:
                </p>
                <div className="d-flex align-items-center" id="iconsFooter">
                    <a href="https://www.facebook.com/Hospitelec-104000000000000" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-facebook-square mx-3"></i>
                    </a>
                    <a href="https://www.instagram.com/hospitelec/" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-whatsapp-square mx-3"></i>
                    </a>
                    <a href="https://www.instagram.com/hospitelec/" target="_blank" rel="noopener noreferrer">
                        <i className="fa-brands fa-instagram-square mx-3"></i>
                    </a>
                </div>
            </div>

            <hr></hr>

            <div className="text-center container text-white pb-4">
                Descargo de responsabilidad: La Teleorientación Médica Gratuita de Hospitelec no sustituye la consulta médica, ni es un servicio de urgencias. La TMG es un beneficio de apoyo gratuito de orientación a la población en temas de salud, bienestar y estilo de vida.
            </div>
            
            <div className="text-center container text-white pb-4">
                <a className="mx-3 btn btn-white" href="https://www.iubenda.com/privacy-policy/37183673" target="_blank">
                    Política de privacidad
                </a>
                <a className="mx-3 btn btn-white" href="https://www.iubenda.com/privacy-policy/37183673/cookie-policy" target="_blank">
                    Política de cookies
                </a>
            </div>

        </footer>
    )
}