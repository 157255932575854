import "./NuestrasEspecialidades.css"

function NuestrasEspecialidades ({imagenNeu, imagenRehabilitacion, imagenMedico, imagenCardio, imagenOdonto}){
    return(

        <div className="container-fluid nuestrasEspecialidades" id="servicios">

            <div className="container noPadding-sm noPadding-xs">

                <h1 className="titulo__especialidades">NUESTRAS ESPECIALIDADES PARA UNA TMG</h1>

                <div className="especialidades row">

                    <div className="col-xl col-lg col-md-12 col-sm-12">

                        <a href="#" className="especialidades__neorologia">

                            <img src={imagenNeu} alt="" className="neurologia__img" />

                            <p className="especialidades__text">Psicología</p>

                        </a>

                    </div>

                    <div className="col-xl col-lg col-md-6 col-sm-12">

                        <a href="#" className="especialidades__rehabilitacion">

                            <img src={imagenRehabilitacion} alt="" className="rehabilitacion__img" />

                            <p className="especialidades__text">Rehabilitación física</p>

                        </a>

                    </div>

                    <div className="col-xl col-lg col-md-6 col-sm-6">

                        <a href="#" className="especialidades__medicoGeneral">

                            <img src={imagenMedico} alt="" className="medicoGeneral__img" />

                            <p className="especialidades__text">Medicina general</p>

                        </a>

                    </div>

                    <div className="col-xl col-lg col-md-6 col-sm-6">

                        <a href="#" className="especialidades__cardiologo">

                            <img src={imagenCardio} alt="" className="cardiologo__img" />

                            <p className="especialidades__text">Nutrición clínica</p>

                        </a>

                    </div>

                    <div className="col-xl col-lg col-md-6 col-sm-6">

                        <a href="#" className="especialidades__odontologia">

                            <img src={imagenOdonto} alt="" className="odontologia__img" />

                            <p className="especialidades__text">Odontologia</p>

                        </a>

                    </div>

                </div>

            </div>
            
        </div>

        
    )
}

export default NuestrasEspecialidades;