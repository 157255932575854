import "./ComoFunciona.css"

function ComoFunciona ({imagenSolicita, imagenEspera, imagenUnDoctor, imagenWA}){
    return(

        <div className="container-fluid comoFunciona" id="comoFunciona">

            <div className="noPadding-sm noPadding-xs comoFunciona__container">

                <h1 className="comoFunciona__titulo">¿CÓMO FUNCIONA LA TMG?</h1>

                <div className="row comoFunciona__row">

                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">

                        <div className="comoFunciona__pasos">

                            <img src={imagenSolicita} alt="" className="pasos__img" />

                            <div className="pasos__info">

                                <span className="info__numero">1.</span>

                                <p className="info__text">Llena el formulario con tus datos. Compártenos más detalles y tus dudas.
                                    <br></br>
                                    <small className=""><b>Dale en:</b> Enviar para iniciar sesión de TMG</small>
                                
                                </p>

                            </div>

                        </div>

                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">

                        <div className="comoFunciona__pasos">

                            <img src={imagenEspera} alt="" className="pasos__img" />

                            <div className="pasos__info">

                                <span className="info__numero">2.</span>

                                <p className="info__text">Tu  solicitud pasa a nuestra sala de espera virtual para poder asignarte, en unos minutos, al especialista idóneo.</p>

                            </div>

                        </div>
                        
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">

                        <div className="comoFunciona__pasos">

                            <img src={imagenWA} alt="" className="pasos__img" />

                            <div className="pasos__info">

                                <span className="info__numero">3.</span>

                                <p className="info__text">Cuando esté disponible el especialista, te enviaremos un mensaje de WhatsApp para que te conectes a tu sesión de TMG.</p>

                            </div>

                        </div>
                        
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">

                        <div className="comoFunciona__pasos">

                            <img src={imagenUnDoctor} alt="" className="pasos__img" />

                            <div className="pasos__info">

                                <span className="info__numero">4.</span>

                                <p className="info__text">
                                    Te conectas a tu sesión en tu dispositivo.
                                    <br></br>
                                    Interactúas con nuestros especialistas.
                                    <br></br>
                                    Finalizas tu sesión de TMG.
                                </p>

                            </div>

                        </div>
                        
                    </div>

                </div>

            </div>

        </div>

    )
}

export default ComoFunciona;