import React from "react";
import Swal from 'sweetalert2'
import Rater from 'react-rater'
import { useLocation } from 'react-router-dom';
import fetchData from './../../services/fetchData';
import config from "./../../config";

import 'react-rater/lib/react-rater.css'
import "./CalificarConsulta.css";

const URLAPI = config.urlApi;

export default function CalificarConsulta(){
    const location = useLocation();
    const idCita = location.pathname.split('/')[2];
    const [ratingCita, setRatingCita] = React.useState(0);
    const [ratingDoctor, setRatingDoctor] = React.useState(0);

    const onSubmitCalificacion = (e) => {
        e.preventDefault();
        if(ratingCita === 0 || ratingDoctor === 0){
            Swal.fire({
                title: 'Olvidaste algo',
                text: 'Califica con 1/5 estrellas la cita y el doctor',
                icon: 'info',
                timer:3000,
                showConfirmButton: false
            })
            return;
        }

        let values = new FormData(e.target)
        values.append('calificacion', ratingCita);
        values.append('calificacionDoctor', ratingDoctor);
        values.append('idCita', idCita);
        let objectValues = Object.fromEntries(values);
        console.log(objectValues);
        fetchData('POST', `${URLAPI}cita/${idCita}/calificar`, objectValues)
            .then(data => {
                if(data.response === 'success'){
                    Swal.fire({
                        title: '¡Listo!',
                        text: 'Tu calificación se envió correctamente, muchas gracias por tu tiempo.',
                        icon: 'success',
                        timer:1500,
                        showConfirmButton: false
                    }).then(() => {
                        window.location.href = `/`;
                    })
                    console.log(data)
                } else {
                    console.log('error al calificar una cita');
                }
            })
            .catch(err => {
                console.log(`ha petao el servidor ${err}`);
            })
    }

    const onRateCita = ({ rating })=>{
        setRatingCita(rating)
    }
    
    const onRateDoctor = ({ rating })=>{
        setRatingDoctor(rating)
    }

    return(
        <section className="h-100">
            <div id="gracias" className="container-fluid">
                <div className="container">
                    <h1>Gracias por confiar en nosotros.</h1>
                    <form onSubmit={onSubmitCalificacion}>
                        <div className="text-center d-flex justify-content-around align-items-center">
                            <p>Califica la cita:</p>
                            <Rater total={5} rating={0} onRate={onRateCita} />
                        </div>
                        <div className="text-center d-flex justify-content-around align-items-center">
                            <p>Califica el doctor:</p>
                            <Rater total={5} rating={0} onRate={onRateDoctor} />
                        </div>
                        <textarea name="opinion" placeholder="Escribe un comentario aquí. Es opcional." className="datos__input"></textarea>
                        <br></br>
                        <button className="saludProfesional__button">Enviar calificación</button>
                    </form>
                    <hr className="my-5"></hr>
                    <div className="text-center">
                        <p>Puedes agendar una cita presencial pulsando aquí:</p>
                        <a href="https://api.whatsapp.com/send?phone=525538294464" target="_blank" className="btn btn-whatsapp" rel="noreferrer">
                            <i className="fab fa-whatsapp mr-4"></i> &nbsp;
                            WhatsApp
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}